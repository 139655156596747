html{
  height:100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:RGBA(0,0,0,0.05);
  height:100%;
}

#root{
  height:100%;
}

.App{
  height:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media(max-width: 576px){
 
}

@media(max-width: 768px){
  h2{
    font-size:1.3rem;
  }
  .hide-mobile{
    display:none;
  }
}

@media(max-width: 992px){

}

@media(max-width: 1200px){

}