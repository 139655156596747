@media(max-width: 768px){
    .Content.layout_2 .container-fluid{
        min-height:auto !important;
        height:auto !important;
    }    

    .layout2{
        min-height:250px;
        display: flex;
        justify-content:center;
        align-items: center;
        margin-bottom:100px;
    }
}