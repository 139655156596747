.mb-survey{
    background:#f3f4f8;
    padding:30px;
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
}

.mb-survey-1 .mb-survey{
    position:relative !important;
    width: 100%;
    min-height:300px !important;
    height:auto !important;
}

.mb-survey h3{
    font-family: Georgia, Didot, serif;
    color:#283572;    
}

.mb-input-radio{
    border: 1px solid RGBA(0,0,0,0.2);    
    border-radius: 3px;
}

.question-container{
    max-width:600px;
}

.question-container-scroll{
    max-width:700px;
    max-height:100%;
    overflow-y:scroll;
    overflow-x:auto;
    padding:10px;
}

.mb-overflowy-scroll{
    overflow-y: scroll;
}

.mb-survey-loading{
    position:absolute;
    top:0;
    left:0;
    right:0;
    height:10px;
}

.mb-survey-loading-bar{
    height:10px;
    background:#3cf5a4;
    transition-property: width;
    transition-duration: 0.5s;
    transition-delay: 0s;
}

.next-step-1{
    right:25px;
}

.next-step-2{
    right:25px;
}

.next-step-3{
    right:25px;
}

@media(max-width: 768px){
    h3{
        font-size:1.2rem;
    }     
    .mb-survey-2 .mb-survey{
        position:initial !important;
        min-height:300px !important;
    }
    .mb-survey-3 .mb-survey{
        position:initial !important;
        height:auto !important;        
        min-height:100vh !important;
        padding:30px 30px 60px 30px;
    }
    .mb-survey-3.mb-survey-3-p1 .mb-survey{
        position:initial !important;
        height:auto !important;
        min-height:auto !important;
        padding:30px;
    }
    .next-step-2{
        bottom:-50px !important;
        right:50px !important;
    }    
    .back-step-2{
        bottom:-50px !important;
        left: 50px !important;
    }
    .next-step-1{
        bottom:-60px !important;
    }    
    .back-step-1{
        bottom:-60px !important;
    }
    .next-step-3{
        bottom:-50px !important;
        right:50px !important;
    }    
    .back-step-3{
        bottom:25px !important;        
    }
    .short-form-group{
        margin-top:15px;
    }
    .question-container-scroll{
        max-width:700px;
        max-height:100%;
        overflow-y:auto;
        overflow-x:auto;
        padding:10px;
    }
    
}

@media(max-width: 768px), (max-height:700px){
    .mb-survey-3 .mb-survey{
        position:initial !important;
        height:auto !important;        
        min-height:700px !important;
        padding:30px 30px 60px 30px;
    }
    .mb-survey-3.mb-survey-3-p1 .mb-survey{
        position:initial !important;
        height:auto !important;
        min-height:auto !important;
        padding:30px;
    }
}

@media(max-width: 992px){
    .next-step-1{
        bottom:25px;
    }    
    .back-step-1{
        bottom:25px;
    }
    .mb-survey{
        height:100%;
    }
}

@media(max-width: 1200px){
    .next-step-2{
        bottom:25px;
    }    
    .back-step-2{
        bottom:25px;
    }  
    .next-step-3{
        bottom:25px;
    }    
    .back-step-3{
        bottom:25px;
    }  
}