.footer {
    height: 300px;
}

ul.footer-links{
    list-style:none;
    margin:0px;
    padding:0px;
}

ul.footer-links li{
    display:inline-block; 
    padding: 0px 10px;   
}

main.Content.layout_1{
    height:auto;
}

main.Content.layout_2{
    height:100%;
}

main.Content.layout_3{
    height:100%;
}

@media(max-width: 576px){
    main.Content.layout_2{
        height:auto;
    }
    main.Content.layout_3{
        height:auto;
    }
}

@media(max-width: 768px){
    .footer {
        height: fit-content;
    }
}

@media(max-width: 992px){

}

@media(max-width: 1200px){

}