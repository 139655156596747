@media(max-width: 768px){
    .Content.layout_3 .container-fluid{
        min-height:auto !important;
        height:auto !important;
    }    

    .layout3{
        padding:0px;
        min-height:250px;
        display: flex;
        justify-content:center;
        align-items: center;
    }
    
}