.adlistitem-logo{
    background-size:contain;
    background-position:center;
    background-repeat:no-repeat;
    min-width:200px;
}

.adlistitem-flex{
    display: flex !important;
}

.adlistitem{
    font-size:0.80rem !important;
}

@media(max-width: 768px){
    .adlistitem{
        font-size:0.75rem !important;
    }
    .adlistitem-flex{
        display: flex !important;
        flex-direction: column !important;
    }
    .adlistitem-logo{
        background-size:contain;
        background-position:center;
        background-repeat: no-repeat;
        min-width:200px;
        height:100px;
        max-height:100px;        
    }
}